<template>
  <div class="marginrecord-card">
    <el-card>
      <div slot="header">
        <div class="bc">
          <div class="bc-l1"></div>
          <div class="bc-t1">保证金</div>
          <div class="bc-l2"></div>
          <div class="bc-t2">缴纳的保证金会冻结在平台，退出平台时会退还保证金余额。</div>
          <router-link to="/account/cashdeposit">
            <div class="bc-t3">缴纳保证金</div>
          </router-link>
        </div>
        <el-form class="searchFrom square-input-warp square-btn-warp" size="small" :inline="true"
                 lable-width="80px" :model="searchForm">
          <el-form-item label="支付状态:">
            <el-select v-model="searchForm.paytype" placeholder="全部">
              <template v-for="payType in payType">
                <el-option :key="payType.value" :label="payType.name"
                           :value="payType.value"></el-option>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item label="资金类型:">
            <el-select v-model="searchForm.type" placeholder="全部">
              <template v-for="type in type">
                <el-option :key="type.value" :label="type.name" :value="type.value"></el-option>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item label="记录时间:">
            <el-date-picker
              v-model="searchForm.createTimeLimit"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
          <el-button size="small" @click="getmarginrecord" type="primary">搜索</el-button>
        </el-form>
      </div>
      <el-row class="sctp-bg-f8 sctp-pad-tb10">
        <el-col :span="3">
          <div class="sctp-tc">支付方式</div>
        </el-col>
        <el-col :span="3">
          <div class="sctp-tc"> 金额</div>
        </el-col>
        <el-col :span="3">
          <div class="sctp-tc">资金类型</div>
        </el-col>
        <el-col :span="4">
          <div class="sctp-tc">订单号</div>
        </el-col>
        <el-col :span="2">
          <div class="sctp-tc">状态</div>
        </el-col>
        <el-col :span="5">
          <div class="sctp-tc">备注</div>
        </el-col>
        <el-col :span="4">
          <div class="sctp-tc">记录时间</div>
        </el-col>
      </el-row>
      <template v-for="marginrecord in recordData">
        <div :key="marginrecord.id" class="marginrecord-item">
          <el-row type="flex" align="middle" class="sctp-pad-tb15">
            <el-col :span="3">
              <div class="sctp-tc">
                {{ marginrecord.paytype | payTypeFilter }}
              </div>
            </el-col>
            <el-col :span="3">
              <div class="sctp-tc">{{ marginrecord.money | moneych }}</div>
            </el-col>
            <el-col :span="3">
              <div class="sctp-tc"> {{ marginrecord.type | TypeFilter }}</div>
            </el-col>
            <el-col :span="4">
              <div class="sctp-tc">{{ marginrecord.orderno }}</div>
            </el-col>
            <el-col :span="2">
              <div class="sctp-tc" v-if="marginrecord.type==2">
                {{ marginrecord.auditflag | AuditflagFilter }}
              </div>
              <div class="sctp-tc" v-if="marginrecord.type !=2">已到账</div>
            </el-col>
            <el-col :span="5">
              <div class="sctp-tc" v-if="marginrecord.type==2">{{ marginrecord.auditdemo }}</div>
              <div class="sctp-tc" v-if="marginrecord.type !=2">{{ marginrecord.remarks }}</div>
            </el-col>
            <el-col :span="4">
              <div class="sctp-tc">{{ marginrecord.createtime }}</div>
            </el-col>
          </el-row>
        </div>
      </template>
      <div class="sctp-pad-tb20 sctp-flex sctp-flex-fe">
        <el-pagination
          :current-page="page"
          :page-size="limit"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 30, 50]"
          layout="prev, pager, next, total, sizes"
          :total="total">
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
const payTypeOptions = [
  {type: 1, name: '支付宝支付'},
  {type: 2, name: '微信支付'},
  {type: 3, name: '钱包支付'},
  {type: 0, name: '提取保证金'}
]
const auditflagFilter = [
  {type: 0, name: '待审核'},
  {type: 1, name: '已到账'},
  {type: 2, name: '审核不通过'}
]
const TypeOptions = [
  {type: 1, name: '缴纳'},
  {type: 2, name: '提取'},
  {type: 3, name: '扣除'},
]
export default {
  name: 'mymarginrecord',
  data() {
    return {
      searchForm: {
        createTimeLimit: null,
        paytype: null,
        type: null
      },
      recordData: [],
      page: 1,
      limit: 10,
      total: 0
      , pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
    }
  }, filters: {
    payTypeFilter(type) {
      // 在过滤 器当中不能引用当前实例 this   this.payTypeOptions 错误的
      const payObj = payTypeOptions.find(obj => obj.type === type)
      // 非空返回类型名称
      return payObj ? payObj.name : null
    },
    AuditflagFilter(type) {
      // 在过滤 器当中不能引用当前实例 this   this.payTypeOptions 错误的
      const payObj = auditflagFilter.find(obj => obj.type === type)
      // 非空返回类型名称
      return payObj ? payObj.name : null
    },
    TypeFilter(type) {
      // 在过滤 器当中不能引用当前实例 this   this.payTypeOptions 错误的
      const payObj = TypeOptions.find(obj => obj.type === type)
      // 非空返回类型名称
      return payObj ? payObj.name : null
    }, moneych(value) {
      var value = Math.round(parseFloat(value) * 100) / 100;
      var s = value.toString().split(".");
      if (s.length == 1) {
        value = value.toString() + ".00";
        return value;
      }
      if (s.length > 1) {
        if (s[1].length < 2) {
          value = value.toString() + "0";
        }
        return value;
      }
    }
  },
  methods: {
    getmarginrecord() {
      const searchForm = JSON.parse(JSON.stringify(this.searchForm))
      if (searchForm.createTimeLimit) {
        searchForm.createStart = this.dateToString(new Date(searchForm.createTimeLimit[0]),
          'yyyy-MM-dd 00:00:00')
        searchForm.createEnd = this.dateToString(new Date(searchForm.createTimeLimit[1]),
          'yyyy-MM-dd 23:59:59')
        delete searchForm.createTimeLimit
      }
      this.$request.post({
        reqcode: '1173',
        reqdesc: '我的保证金记录',
        ...searchForm,
        userid: this.user.userId,
        page: this.page,
        limit: this.limit
      }).then(res => {
        this.recordData = res.resdata.reloadList;
        this.total = res.resdata.count;
        res.retdata.reloadList.forEach(item => {
          if (item.createtime) {
            item.createtime = this.dateToString(new Date(item.createtime), 'yyyy-MM-dd HH:mm:ss')
          }
        })
      })
    },
    handleSizeChange(limit) {
      this.limit = limit
      this.getmarginrecord()
    },
    handleCurrentChange(page) {
      this.page = page
      this.getmarginrecord()
    }
  },
  computed: {
    selectAll() {
      let flag = false
      if (this.recordData && this.recordData.length > 0) {
        flag = this.recordData.every(marginrecord => {
          return marginrecord.selected
        })
      }
      return flag
    },
    indeterminate() {
      if (this.selectAll) {
        return false
      } else {
        let flag = false
        if (this.recordData) {
          flag = this.recordData.some(marginrecord => {
            return marginrecord.selected
          })
        }
        return flag
      }
    },
    payType() {
      return [
        {name: '全部', value: null},
        {name: '支付宝支付', value: 1},
        {name: '微信支付', value: 2},
        {name: '钱包支付', value: 3},
      ]
    }, type() {
      return [
        {name: '全部', value: null},
        {name: '缴纳', value: 1},
        {name: '提取', value: 2},
        {name: '扣除', value: 3}
      ]
    }
  },
  beforeMount() {
    this.getmarginrecord()
  }
}
</script>

<style scoped lang="scss">
.marginrecord-card ::v-deep {
  .el-input {
    width: 110px !important;
  }

  .el-card__body, .el-card__header {
    padding: 12px;
  }

  .bc {
    position: relative;
    display: flex;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 20px;

    .bc-l1 {
      width: 3px;
      height: 20px;
      background-color: darkorange;
      margin-right: 10px;
    }

    .bc-l2 {
      width: 2px;
      height: 15px;
      background-color: #b0b0b0;
      margin: 4px 20px 0 20px;
    }

    .bc-t3 {
      font-size: 13px;
      color: #939090;
      line-height: 24px;
      position: absolute;
      right: 50px;
    }

    .bc-t2 {
      font-size: 13px;
      color: #939090;
      line-height: 24px
    }

  }
}

.el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
  width: 280px;
}

.marginrecord-item {
  border: 1px solid #e5e5e5;
  margin-top: 15px;
  vertical-align: middle;
}

.header-total {
  background-color: white;
  margin-bottom: 7px;
}

.total-list {
  display: flex;
}

.total {
  width: 15%;
  margin: 0px 10px 20px 10px;
  padding: 10px;
  height: 70px;
  text-align: center;
  line-height: 20px;
  background-color: rgba(255, 222, 174, 1);
}

.total-type {
  font-size: 14px;
  margin-bottom: 7px;
}

.total-value {
  font-size: 18px;
  color: #F60;
}
</style>
